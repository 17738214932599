import React, { Component } from "react"
import parse from 'html-react-parser';
import { withTranslation } from 'gatsby-plugin-react-i18next';
import GoogleMap from '../components/GoogleMap';
import ContentImage from '../components/ContentImage';
import { sectionMapping } from '../components/Experience';

class InformationPanel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showMore: props.isOpen ?? false
		};
	}

	static defaultProps = {
		showMap: false,
		zoom: 16
	  };
	
	// Check description section to apply specific styles
	isDescription(id) {
		return id === sectionMapping["full-description"].id + '_1' ? 'descripcion-completa' : ''
	}

  	render() {
		const replaceOptions = {
			trim: this.props.trim ?? true,
			replace: domNode => {
				// Ignoring errors due to rich text format
				if(domNode.name === 'o:p') {
					return <React.Fragment></React.Fragment>;
				}
				if (domNode.name === 'img' && domNode.attribs.src && domNode.attribs.src.startsWith('/')) {
					return <ContentImage image={domNode}/>
				}
			}
		};

		return	(
			<div className={`mb-3 masca destacado-informacion`}>
				<div className={`col-12 destacado-informacion-cabecera descripcion-completa clickable ${this.props.isOpen ? '' : 'collapsed'}`} role={`button`} tabIndex={0} data-toggle="collapse" data-target={`#` + this.props.id + `_body`} onClick={() => this.setState({ showMore: !this.state.showMore })} onKeyPress={this.handleKeyPress}>
					<h3 className={`panel-title`}>
						<a className={`titulo ${this.props.isOpen ? '' : 'collapsed'}`} role="button" data-toggle="collapse" href={`#` + this.props.id + `_body`} onClick={() => this.setState({ showMore: !this.state.showMore })} aria-controls="panelCollapse-1">
							<span>{this.props.title}</span>
							<div className={`more-less`}>
								{!this.state.showMore ? 
									<svg width="1em" height="1em" viewBox="0 0 16 16" className={`bi bi-plus`} fill="currentColor" xmlns="http://www.w3.org/2000/svg">
										<path fillRule={`evenodd`} d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
									</svg>
								:
									<svg width="1em" height="1em" viewBox="0 0 16 16" className={`bi bi-dash`} fill="currentColor" xmlns="http://www.w3.org/2000/svg">
										<path fillRule={`evenodd`} d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
									</svg>
								}
							</div>
						</a>
					</h3>
				</div>
				<div id={this.props.id + `_body`} className={`destacado-informacion-cuerpo ${this.props.isOpen ? 'show' : 'collapse'}`}>
					{this.props.showMap && this.props.coordinates ?
						<GoogleMap classes={`pt-3`} lat={this.props.coordinates.lat} lng={this.props.coordinates.lng}></GoogleMap>
						:	
						<div className={`pt-3 ${this.isDescription(this.props.id)}` }>
							{this.props.body ? parse(this.props.body, replaceOptions) : ''}
						</div>
					}
				</div>
			</div>
  	)}
}

export default withTranslation('InformationPanel')(InformationPanel);
