import React, { Component } from "react"
import parse from 'html-react-parser';
import { withTranslation, Link } from 'gatsby-plugin-react-i18next';
import { parseCurrency } from './lib/utils'
import esp from "../../static/espanol.png"
import ale from "../../static/aleman.png"
import fra from "../../static/frances.png"
import ing from "../../static/ingles.png"
import ned from "../../static/netherlands.png"
import ita from "../../static/italiano.png"
import pol from "../../static/polaco.png"
import ContentImage from "./ContentImage";
import {onAddToCartDL} from "./lib/dataLayer";

function WeekDays(weekdays, t) {
	const availableWeekDays = {
		1: "Lunes",
		2: "Martes",
		4: "Miércoles",
		8: "Jueves",
		16: "Viernes",
		32: "Sábado",
		64: "Domingo",
	};
	const processWeekDays = (days) => {
		return Object.keys(availableWeekDays)
			.filter((key) => days & key)
			.map((key) => availableWeekDays[key]);
	};

	const weekdaysAvailable = processWeekDays(parseInt(weekdays, 2));

	let textReturn;

	if (weekdaysAvailable.length === 7) {
		textReturn = <span className="mt-2">{t("Todos los días")}</span>;
	} else if (weekdaysAvailable.length === 0) {
		textReturn = <span className="mt-2">{t("Sin fechas disponibles")}</span>;
	} else {
		textReturn = weekdaysAvailable.map((day, index) => (
			<span key={index} className="mt-2">{t(day)}</span>
		));
	}
	return textReturn;
}

class Product extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showMore: false
		};
	}

	render() {
		const { t } = this.props;

		const replaceOptions = {
			trim: true,
			replace: domNode => {
				if (domNode.name === 'img' && domNode.attribs.src && domNode.attribs.src.startsWith('/')) {
					return <ContentImage image={domNode}/>
				} else {
					return domNode
				}
			}
		};

		return	<div className={`mb-3 masca destacado-producto`}>
			<div className={`row destacado-producto-cabecera`}>
				<div className={`col-6 col-sm-10 text-left`}>
					<h3 className={`nombre`}>{this.props.product.name}</h3>
					{this.props.product.languages && (
						<div className="banderas text-left">
							{this.props.product.languages.includes("es") ? (
								<img src={esp} alt="Español" />
							) : null}
							{this.props.product.languages.includes("en") ? (
								<img src={ing} alt="English" />
							) : null}
							{this.props.product.languages.includes("fr") ? (
								<img src={fra} alt="Français" />
							) : null}
							{this.props.product.languages.includes("de") ? (
								<img src={ale} alt="Deutsche" />
							) : null}
							{this.props.product.languages.includes("nl") ? (
								<img src={ned} alt="Netherlands" />
							) : null}
							{this.props.product.languages.includes("it") ? (
								<img src={ita} alt="Italiano" />
							) : null}
							{this.props.product.languages.includes("pl") ? (
								<img src={pol} alt="Polish" />
							) : null}
						</div>
					)}
				</div>
				<div className={`col-6 col-sm-2 text-center`}>
					<Link to={`/booking/experience/${this.props.experienceId}/product/${this.props.product.id}/`}
						  onClick={() => {
							  onAddToCartDL(this.props.experience, "boton listado")
						  }}
						  rel={'nofollow'}
					>
						<button className={`btn btn-primary btn-sm`} rel="nofollow">
							<span>{t("comprar")}</span>
						</button>
					</Link>
				</div>
				{this.props.product.weekDays && (
					<div className="col-12 flex-1">
						<div className="d-flex flex-wrap week-days pl-2 pr-2 pb-2 mt-2">
							<svg
								className="mt-2"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 17.29 17.68"
							>
								<g id="Capa_2" data-name="Capa 2">
									<g id="Capa_1-2" data-name="Capa 1">
										<path
											className="cls-1"
											d="M6.25,9.75a.4.4,0,0,0-.41-.4H4.44a.4.4,0,0,0-.4.4v1.4a.39.39,0,0,0,.4.4h1.4a.4.4,0,0,0,.41-.4Z"
										/>
										<path
											className="cls-1"
											d="M9.74,9.75a.39.39,0,0,0-.4-.4H7.94a.4.4,0,0,0-.4.4v1.4a.39.39,0,0,0,.4.4h1.4a.39.39,0,0,0,.4-.4Z"
										/>
										<path
											className="cls-1"
											d="M13.24,9.75a.39.39,0,0,0-.4-.4h-1.4a.4.4,0,0,0-.4.4v1.4a.39.39,0,0,0,.4.4h1.4a.39.39,0,0,0,.4-.4Z"
										/>
										<path
											className="cls-1"
											d="M6.25,13.25a.4.4,0,0,0-.41-.4H4.44a.4.4,0,0,0-.4.4v1.4a.39.39,0,0,0,.4.4h1.4a.4.4,0,0,0,.41-.4Z"
										/>
										<path
											className="cls-1"
											d="M9.74,13.25a.39.39,0,0,0-.4-.4H7.94a.4.4,0,0,0-.4.4v1.4a.39.39,0,0,0,.4.4h1.4a.39.39,0,0,0,.4-.4Z"
										/>
										<path
											className="cls-1"
											d="M13.24,13.25a.39.39,0,0,0-.4-.4h-1.4a.4.4,0,0,0-.4.4v1.4a.39.39,0,0,0,.4.4h1.4a.39.39,0,0,0,.4-.4Z"
										/>
										<path
											className="cls-1"
											d="M15.75,2V4.11A1.75,1.75,0,0,1,14,5.85H12.89a1.76,1.76,0,0,1-1.76-1.74V2h-5V4.11A1.75,1.75,0,0,1,4.39,5.85H3.29A1.74,1.74,0,0,1,1.54,4.11V2A1.58,1.58,0,0,0,0,3.55V16.09a1.59,1.59,0,0,0,1.58,1.59H15.7a1.59,1.59,0,0,0,1.59-1.59V3.55A1.59,1.59,0,0,0,15.75,2ZM15.24,15.3a.69.69,0,0,1-.69.69H2.71A.69.69,0,0,1,2,15.3V8.83a.68.68,0,0,1,.69-.68H14.55a.68.68,0,0,1,.69.68Z"
										/>
										<path
											className="cls-1"
											d="M3.29,4.71H4.38a.6.6,0,0,0,.6-.6V.6a.6.6,0,0,0-.6-.6H3.29a.6.6,0,0,0-.6.6V4.11a.6.6,0,0,0,.6.6Z"
										/>
										<path
											className="cls-1"
											d="M12.88,4.71H14a.6.6,0,0,0,.6-.6V.6A.6.6,0,0,0,14,0H12.88a.6.6,0,0,0-.6.6V4.11a.6.6,0,0,0,.6.6Z"
										/>
									</g>
								</g>
							</svg>
							{WeekDays(this.props.product.weekDays, t)}
						</div>
					</div>
				)}

				<div className={`col-6 col-sm-8 offset-sm-2 pb-2 leer-mas text-center`}>
					<a role="button" data-toggle="collapse" href={`#` + this.props.id + `_description`} className={`collapsed`}>
						{!this.state.showMore ?
							<span className={`clickable`} role={`button`} tabIndex={0} data-toggle="collapse" data-target={`#` + this.props.id + `_description`} onClick={() => this.setState({ showMore: true })} onKeyPress={this.handleKeyPress}>
										<svg width="1em" height="1em" viewBox="0 0 16 16" className={"bi bi-plus-circle-fill"} fill="currentColor" xmlns="http://www.w3.org/2000/svg">
											<path fillRule={`evenodd`} d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"></path>
										</svg>
								&nbsp;
								{t("Leer más")}
									</span>
							:
							<span className={`clickable`} role={`button`} tabIndex={0} data-toggle="collapse" data-target={`#` + this.props.id + `_description`} onClick={() => this.setState({ showMore: false })} onKeyPress={this.handleKeyPress}>
										<svg width="1em" height="1em" viewBox="0 0 16 16" className={"bi bi-dash-circle-fill"} fill="currentColor" xmlns="http://www.w3.org/2000/svg">
											<path fillRule={`evenodd`} d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7z"/>
										</svg>
								&nbsp;
								{t("Leer menos")}
									</span>
						}
					</a>
				</div>
				<div className={`col-6 col-sm-2 offset-sm-0 text-center`}>
					<div>
						<span>{t("desde")}</span>
					</div>
					<div>
						<span className={`precio`}>{this.props.product.lowestRate.formatted}{parseCurrency(this.props.product.lowestRate.currency)}</span>
					</div>
				</div>
			</div>
			<div id={this.props.id + `_description`} className={`destacado-producto-cuerpo collapse`}>
				<div>
					<span>{parse(this.props.product.description.summaryBlock[0].title, {})}</span>
					<div>{parse(this.props.product.description.summaryBlock[0].content, replaceOptions)}</div>
					<span>{parse(this.props.product.description.summaryBlock[1].title, {})}</span>
					<div>{parse(this.props.product.description.summaryBlock[1].content, replaceOptions)}</div>
				</div>
			</div>
		</div>;
	}
}

export default withTranslation('Experience')(Product);
