import React, {useMemo} from 'react';
import Layout from '../components/Layout';
import Experience from '../components/Experience';
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import ContentContext from "../context/ContentContext";
import { useSiteMetadata } from "../hooks/use-site-metadata"
import favicon from "../../static/favicon.svg"
import { useLocation } from "@reach/router";
import { setPathCanonical } from "../components/lib/utils";
import {I18nextContext, useTranslation} from "gatsby-plugin-react-i18next";

export const query = graphql`
	query ExperienceDetails($lang: String, $experienceId: Int) {
		experience(lang: {eq: $lang}, experienceId: {eq: $experienceId}) {
			siteBaseUrl
			canonical_url
			name
			name_spa
			coordinates {
				lat
				lng
			}
			subcategory {
				name
				name_spa
			}
			tags
			difficulty
			difficulty_id
			experienceId
			duration {
				hours
				minutes
				formatted
			}
			images {
				alt
				remoteImage {
					childImageSharp {
						fixed(width: 655, height:311, fit: COVER, cropFocus: CENTER) {
							src
						srcSet
						}
					}
				}
				title
			}
			originalPath
			lowestRate {
				value
				formatted
				currency
			}
			languages
			information {
				body
				title
				class
			}
			description_summary
			description {
				summaryBlock {
					content
					title
				}
				description
			}
			sections {
				id
				editable
				name
				short_name
			}
			seo {
				title
				description
			}
			products {
				id
				name
				weekDays
				languages
				description {
					description
					summaryBlock {
						content
						title
					}
				}
				lowestRate {
					value
					currency
					formatted
				}
				rates {
					id
					pvp
					customerTypes {
						name
					}
				}
			}
			translations {
				lang
				path
			}
			cancelled
	  }
	}
`

export default ( { data } ) => {
	const { t } = useTranslation('Index');
	const experience = data.experience;
    const { title, siteUrl } = useSiteMetadata();
	const htmlTitle = experience.name + " | " + t(title);
	const location = useLocation();
	const memoizedValue = useMemo(() => ({ translations: experience.translations }), [experience.translations]);
	const { language } = React.useContext(I18nextContext);

	return (
		<ContentContext.Provider value={memoizedValue}>
			<Layout>
				<Helmet>
					<html lang={language}/>
					<title>{htmlTitle}</title>
					<link rel="icon" href={favicon} />
					<meta name="title" content={htmlTitle} />
					<meta name="description" content={experience.seo?.description} />
					<meta name="og:title" content={experience.seo?.title} />
					<meta name="og:description" content={experience.seo?.description} />
					<meta name="og:type" content="product" />
					<meta name="og:url" content={setPathCanonical(location.href)} />
					{experience.translations.map((translation) =>
						<link rel="alternate" key={translation.lang} hrefLang={translation.lang} href={siteUrl  + translation.path + '/'} />)
					}
					<link rel="canonical" href={setPathCanonical(location.href)} />
					<body className={`experience`} data-spy="scroll" data-target="#submenu"/>
				</Helmet>
				<Experience experience={experience}/>
			</Layout>
		</ContentContext.Provider>);
}
