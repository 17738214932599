import React, { Component } from "react"
import { withTranslation } from 'gatsby-plugin-react-i18next';

class CardInfo extends Component {
  	render() {
		return	<div className={`text-center ${this.props.classes}`}>
					<div className={`card-info px-3`}>
						{this.props.sections.map((section, i) => {
							return 	<dl key={`card-info-section-` + i} className={`pt-2 mb-1 ${i !== 0 ? "separador-seccion" : ""}`}>
										<dt className={`titulo-seccion`}>
											{section.title}
										</dt>
										{section.highlight ?
											<>
												<dd className={`cuerpo-seccion-precio`}>
													{section.body}
												</dd>
												{section.button !== null &&
													section.button
												}
											</>
										:
											<dd className={`cuerpo-seccion`}>
												{section.body}
											</dd>
										}
									</dl>
						})}
					</div>
				</div>;
	  }
}

export default withTranslation('CardInfo')(CardInfo);